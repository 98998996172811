import { render, staticRenderFns } from "./test5-1.vue?vue&type=template&id=7cf1484a&scoped=true&"
import script from "./test5-1.vue?vue&type=script&lang=js&"
export * from "./test5-1.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/page.css?vue&type=style&index=0&id=7cf1484a&prod&scoped=true&lang=css&"
import style1 from "./test5-1.vue?vue&type=style&index=1&id=7cf1484a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cf1484a",
  null
  
)

export default component.exports