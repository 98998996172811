<template>
  <div>
    <!-- 筛选 -->
    <div class="filters-list">
      <ul class="filters">
        <li class="fitler-item search-filter">
          <el-input
            placeholder="请输入内容"
            v-model="search"
            class="input-with-select"
            prefix-icon="el-icon-search"
            clearable
            @clear="getList"
          >
            <el-button type="primary" slot="append" @click="getLists"
              >搜索</el-button
            >
          </el-input>
        </li>
        <li
          class="fitler-item"
          v-for="(fitler, goodsIndex) in fitlerList"
          :key="goodsIndex"
        >
          <!-- v-show="fitler.itemRowShow" -->
          <span class="nameFrame">{{ fitler.title }}:</span>
          <ul class="cates">
            <li
              :class="{
                active: typeIndex === fitler.index,
              }"
              v-for="(type, typeIndex) in fitler.typeList"
              :key="typeIndex"
              @click="handleclick(typeIndex, fitler, type, goodsIndex)"
            >
              {{ type.name }}
            </li>
          </ul>
        </li>
      </ul>
      <!-- 已经选择： -->
      <ul class="filters" v-if="!showFiltergoods">
        <li class="fitler-item">
          <span class="nameFrame">已经选择：</span>
          <ul class="cates">
            <li
              class="filtered-item"
              v-for="(sureItem, index) in FilterList"
              :key="index"
            >
              {{ sureItem.name }}
              <i class="el-icon-close" @click="minItemClick(index)"></i>
            </li>

            <li class="clear-all-filtered" @click="resetClick">清除筛选</li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- 表格 -->
    <div class="table">
      <div class="header">
        <span
          :class="idx == index ? 'contrast' : ''"
          @click="Csort(i, idx)"
          v-for="(i, idx) in sort"
          :key="idx"
        >
          {{ i }}
          <i class="el-icon-bottom"></i
        ></span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="名称" align="center">
          <template slot-scope="scope" style="text-align: left">
            <p
              style="text-align: left"
              @click="handleEdit(scope.$index, scope.row)"
            >
              {{ scope.row.title }}
            </p>
            <!-- <p
              style="text-align: left;  "
              @click="handleEdit(scope.$index, scope.row)"
              v-html="scope.row.details"
            ></p> -->
          </template>
        </el-table-column>
        <el-table-column prop="provincename" label="发布地区" align="center"  width="150">
        </el-table-column>
        <el-table-column label="发布时间" align="center"  width="150">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="截止时间" align="center"  width="150">
          <template slot-scope="scope">
            {{ scope.row.endtime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="subsidy.name" label="最高补助" align="center"  width="150">
        </el-table-column>
      </el-table>

      <div class="pagination">
        <pagination
          style="padding: 0"
          class="pages"
          v-show="total > 0"
          :total="total"
          layout="total,  prev, pager, next, jumper"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </div>
    </div>
    <!-- 弹出框 -->
    <el-dialog
      class="dialog"
      :title="tit"
      :visible.sync="dia.dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <Xq :item="dia"></Xq>
    </el-dialog>
  </div>
</template>

<script>
import { Fan, PolicyList } from "../../../common/js/api";
import { formatDate } from "../../../common/js/times"; //时间
import Pagination from "@/common/Pagination";
import Xq from "./xq";

export default {
  components: { Pagination, Xq },
  data() {
    return {
      activeName: "tz",
      fitlerList: [], //筛选
      showFiltergoods: true, //已经选择：显示和隐藏
      FilterList: {}, //已经选择数据
      FilterListId: {}, //已经选择数据id
      sort: ["最新", "热度"],
      index: 0,
      arr: [],
      search: "",
      tableData: [],
      total: 0, //总条目数
      listQuery: {
        limit: 10,
        page: 1,
      },
      dia: {
        dialogVisible: false,
        id: "",
      },
      dialogVisible: false,
      tit: "",
      category: 143,
      upeated_at: "",
      views: "",
    };
  },
  props: {
    items: {
      type: Number,
      default: () => {
        return {};
      },
    },
  },
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  watch: {
    items: {
      handler(val, oldVal) {
        if (this.category != val) {
          this.category = val;
          this.getList();
        }
      },
      deep: true,
    },
  },
  created() {
    this.category = this.items;
    this.search = this.$route.query.keyword;
    this.city = this.$route.query.city;
    this.getFan();
    // this.getList();
  },
  methods: {
    // 筛选列表
    getFan() {
      Fan()
        .then((res) => {
          this.fitlerList = res.data;
          for (var i = 0; i < this.fitlerList.length; i++) {
            this.fitlerList[i].typeList.unshift({ name: "全部" });
            this.fitlerList[i].index = 0;
          }
          for (let j = 0; j < this.fitlerList[4].typeList.length; j++) {
            //  console.log(this.fitlerList[4].typeList[j].name );
            let name = this.fitlerList[4].typeList[j].name;
            if (this.city == name.substring(0, 2)) {
              // console.log(this.fitlerList[4].typeList[j].name);
              this.$set(
                this.FilterListId,
                4,
                this.fitlerList[4].typeList[j].name
              ); ///已经选择数据id

              this.$set(this.FilterList, 4, this.fitlerList[4].typeList[j]); ///已经选择数据
              this.fitlerList[4].index = j; //筛选选中index
              this.showFiltergoods = false; //已经选择：显示和隐藏
            }
          }

          this.getList();
        })
        .catch(() => {});
    },
    // 获取列表
    getList() {
      this.getLists(this.FilterListId);
    },
    // 排序
    Csort(item, idx) {
      this.index = idx;
      if (this.index == 0) {
        this.upeated_at = 1;
        this.views = "";
      } else if (this.index == 1) {
        this.upeated_at = "";
        this.views = 2;
      }
      this.getLists(this.FilterListId);
    },
    // 筛选
    handleclick(typeIndex, goods, type, goodsIndex) {
      goods.index = typeIndex; //筛选选中index
      this.showFiltergoods = false; //已经选择：显示和隐藏
      this.$set(this.FilterList, goodsIndex, type);
      if (goodsIndex == "4") {
        this.$set(this.FilterListId, 4, type.name);
      } else if (goodsIndex == "1") {
        this.$set(this.FilterListId, 1, type.name);
      } else {
        this.$set(this.FilterListId, goodsIndex, type.id);
      }

      this.getLists(this.FilterListId);
    },
    getLists(List) {
      if (List[4] == "全部") {
        List[4] = "";
      }
      if (List[1] == "全部") {
        List[1] = "";
      }
      this.$set(this.FilterListId, -1, this.search);
      PolicyList({
        page: this.listQuery.page,
        limits: this.listQuery.limit,
        category: this.category,
        keywords: this.search,
        leveltype: List[0],
        year: List[1],
        type: List[2],
        industry: List[3],
        provincename: List[4],
        subsidy: List[5],
        is_grant: List[6],
        upeated_at: this.upeated_at,
        views: this.views,
      })
        .then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
          for (var i = 0; i < this.tableData.length; i++) {
            this.tableData[i].details = this.tableData[i].details.replace(
              /<\/?(img)[^>]*>/gi,
              ""
            );
          }
        })
        .catch(() => {});
    },
    // 已选择每项的x键
    minItemClick(minIndex) {
      this.$delete(this.FilterList, minIndex);
      this.$delete(this.FilterListId, minIndex);
      this.fitlerList[minIndex].index = 0;
      // this.fitlerList[minIndex].itemRowShow = true;
      this.getLists(this.FilterListId);
      this.Array();
      if (this.arr.length == 0) {
        this.showFiltergoods = true;
      }
    },
    Array() {
      for (let i in this.FilterList) {
        this.arr.push(this.FilterList[i]);
      }
    },
    /* 清除筛选 */
    resetClick() {
      for (var i in this.fitlerList) {
        // this.fitlerList[i].itemRowShow = true;
        this.fitlerList[i].index = 0;
      }
      this.showFiltergoods = true;
      this.FilterList = {};
      this.FilterListId = {};
      this.getLists(this.FilterListId);
    },
    // 详情
    handleEdit(index, row) {
      this.dia.dialogVisible = true;
      this.dia.id = row.id;
      this.tit = row.title;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

 <style scoped src="@/assets/css/page.css"></style>
<style lang="less" scoped>
.el-input-group {
  width: 603px;
  border-radius: 10px;
}
/deep/.el-input-group__append {
  background: #3892eb;
  color: #fff;
  border: 1px solid #3892eb;
}

/deep/.el-pager li,
/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background-color: #f7f4f4;
}
.el-table__row:hover p {
  color: #3892eb;
  cursor: pointer;
}
// 弹出框
.dialog {
  /deep/.el-dialog__body {
    padding: 10px 60px 20px;
    height: 600px;
    overflow-y: scroll;
  }
  /deep/.el-dialog__body::-webkit-scrollbar {
    width: 15px;
  }
  // 修改 滚动条的 下面 的 样式
  // /deep/.el-dialog__body::-webkit-scrollbar-track {
  //   background-color: red;
  //   -webkit-border-radius: 2em;
  //   -moz-border-radius: 2em;
  //   border-radius: 2em;
  // }
  // -修改 滑块
  /deep/.el-dialog__body::-webkit-scrollbar-thumb {
    background-color: #e7e7e7;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  /deep/.el-dialog__header {
    background: #3892eb;
  }
  /deep/.el-dialog__title {
    color: #fff;
    font-size: 20px;
  }
  /deep/.el-dialog__headerbtn {
    top: 60px;
    right: 20px;
    width: 25px;
    height: 25px;
    border: 1px solid #3892eb;
    border-radius: 50%;
    .el-dialog__close {
      color: #3892eb;
    }
  }
}
</style>
