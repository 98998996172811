<template>
  <div class="content-box">
    <div class="content">
      <div class="policylib-result express">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            v-for="i in pane"
            :key="i.index"
            :label="i.lab"
            :name="i.name"
          >
            <Tab :items="category"></Tab>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "./tab";

export default {
  components: { Tab },
  data() {
    return {
      activeName: "tz",
      pane: [
        {
          lab: "通知",
          name: "tz",
        },
        {
          lab: "法规",
          name: "fg",
        },
        {
          lab: "公示",
          name: "gs",
        },
      ],
      category: 143,
    };
  },

  created() {},
  methods: {
    handleClick(tab, event) {
      if (tab.name == "tz") {
        this.category = 143;
      } else if (tab.name == "fg") {
        this.category = 144;
      } else {
        this.category = 145;
      }
      // for (var i = 0; i < this.fitlerList.length; i++) {
      //   this.fitlerList[i].index = 0;
      // }
      // this.FilterList = {};
      // this.FilterListId = {};
      // this.showFiltergoods = true;
      // this.search = "";
      // this.getList();
    },
  },
};
</script>

 <style scoped src="@/assets/css/page.css"></style>
<style lang="less" scoped>
 
/deep/.el-tabs__header {
  padding: 0 43px;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}
 
 
</style>