<template>
  <div>
    <div class="wraps-iim">
      <div class="topeach-policy-list">
        <span class="left-info-tips"
          >
          <!-- <span>正在申报</span><span>还剩8天</span> -->
          </span
        >
        <div class="span-right">
          <span class="right-info-time"
            ><span class="rg-publish-time">发布时间：</span
            ><span>{{ xq.created_at | formatDate }}</span></span
          >
        </div>
      </div>
      <ul class="policy-all-details">
        <li class="each-check-list">
          <span class="rg-publish-time">政策地区：</span
          ><span class="rg-de-box"
            >{{ xq.provincename }}{{ xq.cityname }}{{ xq.areaname }}</span
          >
        </li>
        <li class="each-check-list">
          <span class="rg-publish-time">项目专体：</span
          ><span class="rg-de-box">{{ xq.title }}</span>
        </li>

        <li class="each-check-list">
          <span class="rg-publish-time">起止时间：</span
          ><span class="rg-de-box"
            >{{ xq.created_at | formatDate }}~{{
              xq.endtime | formatDate
            }}</span
          >
        </li>

        <li class="each-check-list">
          <span class="rg-publish-time">最高补助：</span
          ><span class="rg-de-box">
            {{ xq.subsidy ? xq.subsidy.name : "无" }}
          </span>
        </li>
        <li class="each-check-list">
          <span class="rg-publish-time">最高级别：</span
          ><span class="rg-de-box">
            {{ xq.leveltype ? xq.leveltype.name : "无" }}
          </span>
        </li>
        <li class="each-check-list">
          <span class="rg-publish-time">主管部门：</span
          ><span class="rg-de-box"> {{ xq.dept }} </span>
        </li>
      </ul>

      <div class="wrap-all-tipbox">
        <div class="left-info-title" v-show="xq.addfiles">附件</div>

        <div class="wrap-tiptit-bule" v-show="xq.addfiles">
          <i class="el-icon-download"></i>
          <span>附件下载：{{ xq.addfiles }}</span>
        </div>
        <div class="left-info-title">政策详情</div>

        <div class="wrap-tiptit-box">
          <p v-html="xq.details"></p>
        </div>
      </div>
    </div>

    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 15px; position: fixed; margin-left: 37%"
    >
      <el-button type="primary" @click="shenbao()">立即申报</el-button>
    </div>
  </div>
</template>
<script>
import { formatDate } from "../../../common/js/times"; //时间
import { PolicyXq } from "../../../common/js/api";
export default {
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy年MM月dd日");
    },
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      id: "",
      xq: {},
    };
  },
  watch: {
    item: {
      handler(val, oldVal) {
        if (this.item.dialogVisible == true && this.id != val.id) {
          this.id = val.id;
          this.PolicyXq(val.id);
        }
      },
      deep: true,
    },
  },
  created() {
    this.id = this.item.id;
    this.PolicyXq(this.id);
  },
  methods: {
    //   政策详情
    PolicyXq(id) {
      PolicyXq({
        id: id,
      })
        .then((res) => {
          this.xq = res.data;
          this.xq.level = this.xq.leveltype.name;
        })
        .catch(() => {});
    },
    // 立即申报
    shenbao() {
      this.$router.push({
        path: "/chat",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.topeach-policy-list {
  height: 34px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  .left-info-tips {
    display: flex;
    align-items: center;
    span {
      line-height: 20px;
      color: #fc5862;
      background-color: #fabfb4;
      margin-right: 4px;
      padding: 0 4px;
      border-radius: 5px;
    }
  }
  .span-right {
    margin-left: auto;
  }
}
.each-check-list {
  display: flex;
  margin-top: 12px;
}
.left-info-title {
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0 10px;
  color: #010101;
}
.wrap-tiptit-bule {
  color: #3a93eb;
}
.wraps-iim {
  height: 514px;
}
.wrap-tiptit-box {
  line-height: 1.5;
  color: #010101;
  padding-bottom: 50px;
}
</style>